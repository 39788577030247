import Vue from "vue";
import Router from "vue-router";

const LoginView = () => import("@/components/login/login.view.vue");
const EstacionesView = () => import ("@/components/estaciones/estacionesMain.vue");
const RedireccionView = () => import("@/components/redirection/redireccion.vue");
const MainTemplate = () => import("@/components/globales/mainTemplate.vue");
const ConvocatoriasView = () => import("@/components/convocatorias/convocatoriasMain.vue")
const UsuariosView = () => import("@/components/usuarios/usuariosMain.vue")
const EstacionesGeneralView = () => import("@/components/estaciones/estacionesGeneral.vue")
const CodigosView = () => import("@/components/codigos/codigosMain.vue")
const UresView = () => import("@/components/ures/uresMain.vue")
const PadronView = () => import("@/components/padron/padron-view.vue")
const BuscarView = () => import("@/components/credenciales/credenciales-view.vue")


Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '/estaciones-votacion',name: 'Vista Estaciones', component: EstacionesGeneralView},
    { path: '/', redirect: '/login'},
    { path: '/login', name: 'Login', component: LoginView },
    { path: '/redirect/:token', name: 'Redirect', component: RedireccionView },
    { 
      path: '/main', 
      name: 'MainTemplate', 
      component: MainTemplate,
      children:[
        { path: '/convocatorias', name: 'Convocatorias', component: ConvocatoriasView },
        { path: '/convocatoria/:idConvocatoria/estaciones', name: 'Estaciones', component: EstacionesView},
        { path: '/codigos', name: 'Códigos', component: CodigosView},
        { path: '/usuarios', name: 'Usuarios', component: UsuariosView },
        { path: '/ures/:idUre', name: 'Ures', component: UresView },
        { path: '/padron', name: 'Padron', component: PadronView},
        { path: '/subcomisionesFuncionarios', name: 'Credenciales', component: BuscarView},
      ]
    },
    {
      //Cualquier ruta que no exista, se redireccionará al main
      path: '*',
      redirect: '/main',
  }
  ],
});
